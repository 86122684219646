exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-favorite-js": () => import("./../../../src/pages/favorite.js" /* webpackChunkName: "component---src-pages-favorite-js" */),
  "component---src-pages-fleet-js": () => import("./../../../src/pages/fleet.js" /* webpackChunkName: "component---src-pages-fleet-js" */),
  "component---src-pages-fleet-two-js": () => import("./../../../src/pages/fleet-two.js" /* webpackChunkName: "component---src-pages-fleet-two-js" */),
  "component---src-pages-guided-tours-js": () => import("./../../../src/pages/guided-tours.js" /* webpackChunkName: "component---src-pages-guided-tours-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-landing-old-js": () => import("./../../../src/pages/index_landing_old.js" /* webpackChunkName: "component---src-pages-index-landing-old-js" */),
  "component---src-pages-index-old-3-js": () => import("./../../../src/pages/index-old-3.js" /* webpackChunkName: "component---src-pages-index-old-3-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-boat-tours-js": () => import("./../../../src/pages/private-boat-tours.js" /* webpackChunkName: "component---src-pages-private-boat-tours-js" */),
  "component---src-pages-private-tours-js": () => import("./../../../src/pages/private-tours.js" /* webpackChunkName: "component---src-pages-private-tours-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-old-js": () => import("./../../../src/pages/services-old.js" /* webpackChunkName: "component---src-pages-services-old-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-pages-tours-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/tours/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-tours-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

