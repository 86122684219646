/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import './src/components/global.css'



document.addEventListener('DOMContentLoaded', () => {
  /* const loadingComponent = document.querySelector('.loading');
  loadingComponent.classList.add('hidden');
  console.log("Here", loadingComponent) */
});